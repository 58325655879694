@import url("https://fonts.googleapis.com/css?family=Abel|Abril+Fatface|Alegreya|Arima+Madurai|Dancing+Script|Dosis|Merriweather|Oleo+Script|Overlock|PT+Serif|Pacifico|Playball|Playfair+Display|Share|Unica+One|Vibur");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css");
/* Start Global rules */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/* End Global rules */

/* Start body rules */
body {
  background-image: linear-gradient(-225deg, #e3fdf5 0%, #ffe6fa 100%);
  background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
  background-attachment: fixed;
  background-repeat: no-repeat;

  font-family: "Vibur", cursive;
  /*   the main font */
  font-family: "Abel", sans-serif;
  opacity: 0.95;
  /* background-image: linear-gradient(to top, #d9afd9 0%, #97d9e1 100%); */
}

/* |||||||||||||||||||||||||||||||||||||||||||||*/
/* //////////////////////////////////////////// */

/* End body rules */

/* Start form  attributes */
form,
.content {
  width: 450px;
  min-height: 500px;
  height: auto;
  border-radius: 5px;
  margin: 2% auto;
  box-shadow: 0 9px 50px hsla(20, 67%, 75%, 0.31);
  padding: 2%;
  background-image: linear-gradient(-225deg, #e3fdf5 50%, #ffe6fa 50%);
}
/* form Container */
form .con {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  /* align-items: center; */
}
.content .head-form p {
  text-align: center;
  margin: 4px auto;
  opacity: 0.8;
  color: #292a2b;
  font-size: 1em;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row h3 {
  font-family: "Abel", sans-serif;
  color: rgb(24, 23, 23);
  opacity: 0.8;
  font-size: 16px;
}
.row h3 span {
  opacity: 1;
  color: black;
}
.row h4 {
  font-family: "Abel";
  color: rgb(24, 23, 23);
  opacity: 0.8;
  font-size: 16px;
}
.row h4 span {
  opacity: 1;
  color: black;
  font-weight: bold;
}
p.token-text {
  text-align: center;
  margin-top: 7%;
}
p.token-text span {
  font-weight: bold;
}
p.token {
  text-align: center;
  margin-top: 5%;
}
p.token span {
  display: block;
  font-weight: bold;
  font-size: 20px;
}
button.buy-fuel {
  background: #303433;
  color: white;
  align-self: center;
  margin: 10% auto;
  display: block;
}
/* the header form form */
header {
  margin: 2% auto 10% auto;
  text-align: center;
}
.content header {
  margin: 2% auto 5% auto;
  text-align: center;
}
/* Login title form form */
header h2 {
  font-size: 250%;
  font-family: "Playfair Display", serif;
  color: #3e403f;
}
/*  A welcome message or an explanation of the login form */
header p {
  letter-spacing: 0.05em;
}

/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */

.input-item {
  background: #fff;
  color: rgb(169, 169, 169);
  padding: 14.5px 0px 15px 9px;
  border-radius: 5px 0px 0px 5px;
}

/* Show/hide password Font Icon */
#eye {
  background: #fff;
  color: #333;

  margin: 5.9px 0 0 0;
  margin-left: -20px;
  padding: 15px 9px 19px 0px;
  border-radius: 0px 5px 5px 0px;

  float: right;
  position: relative;
  right: 1%;
  top: -0.2%;
  z-index: 5;

  cursor: pointer;
}
/* inputs form  */
input[class="form-input"],
input:disabled {
  background-color: rgb(255, 255, 255);
  width: 240px;
  height: 50px;
  margin-top: 2%;
  padding: 15px;
  font-size: 16px;
  font-family: "Abel", sans-serif;
  color: #5e6472;
  outline: none;
  border: none;
  border-radius: 0px 5px 5px 0px;
  transition: 0.2s linear;
  margin-bottom: 5px;
}
input[id="txt-input"] {
  width: 250px;
}
/* focus  */
input:focus {
  transform: translateX(-2px);
  border-radius: 5px;
}

/* //////////////////////////////////////////// */
/* //////////////////////////////////////////// */

/* input[type="text"] {min-width: 250px;} */
/* buttons  */
button {
  display: inline-block;
  color: #252537;

  width: 280px;
  height: 50px;

  padding: 0 20px;
  background: #fff;
  border-radius: 5px;

  outline: none;
  border: none;

  cursor: pointer;
  text-align: center;
  transition: all 0.2s linear;

  margin: 7% auto;
  letter-spacing: 0.05em;
}
/* Submits */
.submits {
  width: 48%;
  display: inline-block;
  float: left;
  margin-left: 2%;
}

/*       Forgot Password button FAF3DD  */
.frgt-pass {
  background: transparent;
}

/*     Sign Up button  */
.sign-up {
  background: #b8f2e6;
  width: 250px;
}

/* buttons hover */
button:hover {
  transform: translatey(3px);
  box-shadow: none;
}

/* buttons hover Animation */
button:hover {
  animation: ani9 0.4s ease-in-out infinite alternate;
}
@keyframes ani9 {
  0% {
    transform: translateY(3px);
  }
  100% {
    transform: translateY(5px);
  }
}
